
import { CloseCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import BuyDetailedReport from '../CheckupCompleted/components/BuyDetailedReport.vue';

export default defineComponent({
  components: {
    CloseCircleOutlined,
    BuyDetailedReport
  },
  setup() {
    const route = useRoute();

    return {
      session_id: route.query.sid?.toString()
    };
  }
});
